/*global $*/
/*eslint no-undef: "error"*/
export default class ProductGallery {
    classItem = 'product-gallery--item'
    classButton = 'product-gallery--button'
    classBlock = 'product-gallery--block'

    classButtonActive = 'master-gallery__block-active'
    classBlockActive = 'master-gallery__big-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block="${block}"]`).addClass(self.classBlockActive)

            console.log(block)
        })
    }
}
