/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonMobile {
    classBlock = 'button-mobile--block'
    classButton = 'button-mobile--button'

    classBlockOpen = 'menu-footer__item-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
