/*global $*/
/*eslint no-undef: "error"*/

export default class VideoPlayer {
    classButton = 'video-player--button'
    classItemOpen = 'video-player-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            if (!$(this).hasClass(self.classItemOpen)) {
                const videoBlock = document.getElementById($(this).data('id'))

                $(this).addClass(self.classItemOpen)
                videoBlock.play()
            }
        })
    }
}
