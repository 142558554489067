/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonVideo {
    classBlock = 'button-video--block'
    classButton = 'button-video--button'

    classBlockOpen = 'video-block---open'
    classButtonOpen = 'timetable-button-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)

            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
