/*global $*/
/*global Cookies*/
export default class StockYear {
    classButton = 'stock-year--button'
    classClose = 'stock-year--close'
    cookieName = 'i-spa--stock-year'
    constructor () {
        let self = this

        if (!Cookies.get(self.cookieName)) {
            setTimeout(() => {
                $(`.${self.classButton}`).click()
            }, 1500)
        }

        $(`.${self.classClose}`).click(function () {
            Cookies.set(self.cookieName, 1, { expires: 1 })

            $('.is-close-btn').click()
        })
    }
}
