<template lang="pug">
.form
  h4.center(v-html="formTitle" v-show="!sendForm")
  h4.center(v-html="formSuccessTitle" v-show="sendForm")

  .form__block(v-show="!sendForm")
    .form__fields(v-if="formName === 'book'")
      .input-text
        input.input-text__input(
          type="text"
          name="1"
          :placeholder="formFieldNamePlaceholder"
          v-model="fieldName"
        )

      .input-text
        input.input-text__input(
          type="tel"
          name="2"
          :placeholder="formFieldPhonePlaceholder"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

    .form__fields(v-if="formName === 'order'")
      .input-text
        input.input-text__input(
          type="tel"
          name="12345"
          :placeholder="formFieldPhonePlaceholder"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

    .form__fields(v-if="formName === 'review'")
      .input-text
        input.input-text__input(
          type="text"
          name="1"
          :placeholder="formFieldNamePlaceholder"
          v-model="fieldName"
        )

      .input-textarea
        textarea.input-textarea__input(
          :placeholder="formFieldTextPlaceholder"
          v-model="fieldText"
        )

    .form__button
      .button.button-width(:class="{'button-disable': v$.$invalid}" @click="getSend()")
        .button__text(v-html="formButtonName")

    .form__consent
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

  .form__block(v-show="sendForm")
    p.center(v-html="formSuccessText")

</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formName",

    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldText: '',
      files: '',
      consent: ['consent'],
      sendForm: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
          $('.is-close-btn').click()
        }, 3500)
      }
    }
  },

  validations () {
    if (this.formName === 'review') {
      return {
        fieldText: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(400)
        },

        consent: {
          required
        }
      }
    } else {
      return {
        fieldPhone: {
          required,
          minLength: minLength(18),
          maxLength: maxLength(18)
        },

        consent: {
          required
        }
      }
    }
  }
}
</script>
