/*global $*/
/*eslint no-undef: "error"*/
export default class Program {
    classItem = 'program--item'
    classButton = 'program--button'

    classItemOpen = 'open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            console.log('work')
            $(`.${self.classItem}`).not($(this).closest(`.${self.classItem}`)).removeClass(self.classItemOpen)
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
