/*global $*/
/*global Cookies*/
export default class Stock {
    classButton = 'stock--button'
    cookieName = 'i-spa--stock'
    constructor () {
        let self = this

        if (!Cookies.get(self.cookieName)) {
            Cookies.set(self.cookieName, 1, { expires: 1 })

            setTimeout(() => {
                $(`.${self.classButton}`).click()
            }, 10000)
        }
    }
}
